@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;1,400&display=swap');

/* @font-face {
    font-family: 'Inter';
    font-weight: 100 900;
    font-display: block;
    font-style: normal;
    font-named-instance: 'Regular';
    src: url('/fonts/Inter-roman.var.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Inter';
    font-weight: 100 900;
    font-display: block;
    font-style: italic;
    font-named-instance: 'Italic';
    src: url('/fonts/Inter-italic.var.woff2') format('woff2');
  } */

  #__next {
    min-height: 100%;
  }

  .tab {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f1f1f1;
    cursor: pointer;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .active {
    background-color: #ccc;
  }

  #more:hover + #additional {
    display: flex;
  }
  
  #additional:hover {
    display: flex;
  }
  
  #additional {
    display: none;
  }

  textarea {
    height: auto;
    min-height: 1em; /* Set the initial height to one row */
  }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.apexcharts-legend {
  display: flex;
  justify-content: center;
}

.apexcharts-tooltip {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
}